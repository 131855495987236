// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    width: calc(33% - 20px);
    box-sizing: border-box;
  }
  
  .user-card button {
    margin: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/userList.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,0CAA0C;IAC1C,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".user-card {\n    border: 1px solid #ccc;\n    padding: 10px;\n    margin: 10px 0;\n    border-radius: 5px;\n    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);\n    display: inline-block;\n    width: calc(33% - 20px);\n    box-sizing: border-box;\n  }\n  \n  .user-card button {\n    margin: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
