// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles.css */

/* Common styles for all screen sizes */
.StripeElement {
  display: block;
  margin: 10px 0;
  padding: 10px 12px;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  color: #32325d;
  background-color: white;
  border: 1px solid #aab7c4;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
  color: #fa755a;
}

/* Adjust placeholder color */
.StripeElement::placeholder {
  color: #aab7c4;
}

/* Additional styles for the label */
label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

/* Additional styles for error messages */
.error-message {
  color: #fa755a;
  margin-top: 8px;
  font-size: 14px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .StripeElement {
    font-size: 14px;
    padding: 8px 10px;
  }

  label {
    font-size: 12px;
    margin-bottom: 6px; /* Adjust spacing for smaller screens */
  }

  .error-message {
    font-size: 12px;
    margin-top: 6px; /* Adjust spacing for smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Stripe/Styles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf,uCAAuC;AACvC;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,oDAAoD;EACpD,cAAc;EACd,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,+BAA+B;EAC/B,iCAAiC;AACnC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA,6BAA6B;AAC7B;EACE,cAAc;AAChB;;AAEA,oCAAoC;AACpC;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA,yCAAyC;AACzC;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;;AAEA,0CAA0C;AAC1C;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,kBAAkB,EAAE,uCAAuC;EAC7D;;EAEA;IACE,eAAe;IACf,eAAe,EAAE,uCAAuC;EAC1D;AACF","sourcesContent":["/* Styles.css */\n\n/* Common styles for all screen sizes */\n.StripeElement {\n  display: block;\n  margin: 10px 0;\n  padding: 10px 12px;\n  font-size: 16px;\n  font-family: 'Helvetica Neue', Helvetica, sans-serif;\n  color: #32325d;\n  background-color: white;\n  border: 1px solid #aab7c4;\n  border-radius: 4px;\n  box-shadow: 0 1px 3px 0 #e6ebf1;\n  transition: box-shadow 150ms ease;\n}\n\n.StripeElement--focus {\n  box-shadow: 0 1px 3px 0 #cfd7df;\n}\n\n.StripeElement--invalid {\n  border-color: #fa755a;\n  color: #fa755a;\n}\n\n/* Adjust placeholder color */\n.StripeElement::placeholder {\n  color: #aab7c4;\n}\n\n/* Additional styles for the label */\nlabel {\n  display: block;\n  margin-bottom: 8px;\n  font-size: 14px;\n  font-weight: bold;\n}\n\n/* Additional styles for error messages */\n.error-message {\n  color: #fa755a;\n  margin-top: 8px;\n  font-size: 14px;\n}\n\n/* Responsive styles for smaller screens */\n@media (max-width: 768px) {\n  .StripeElement {\n    font-size: 14px;\n    padding: 8px 10px;\n  }\n\n  label {\n    font-size: 12px;\n    margin-bottom: 6px; /* Adjust spacing for smaller screens */\n  }\n\n  .error-message {\n    font-size: 12px;\n    margin-top: 6px; /* Adjust spacing for smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
